import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

import { Link } from 'react-router-dom';


const HeroSection = () => {

  return (
    <div className='hero-container'>
      <video src='/videos/video-2.mp4' autoPlay loop muted />
      <h1>BILLING SOFTWARE SOLUTION</h1>
      <p>User Friendly And Budget Solutions</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          All Type of Hardware!
        </Button>
        
        <Link to={"https://api.whatsapp.com/send?phone=+91%209028414428&text=%20Hello"}>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          // onClick={clickWhatsapp}
          >
          Contact for Demo Now <i className='fab fa-whatsapp' />
          <i class="fa-solid fa-circle-play"></i>
          </Button>
          </Link>
      </div>
    </div>
  );
}

export default HeroSection;
