import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Services() {
  return <>
    <h1 className='services'>
     <div style={{ padding: "2rem", color:'black' }}>
      GST Billing Software - Mall/Agency/Shop, Garment WholeSale/Retail, Restaurant, Lodges, 
        Sweet Shop, Crane Service, Customise Software Avaliable.
      </div>
  </h1>
  <Cards />
      <Footer />
  </>;
    }
