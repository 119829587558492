import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Products() {
  return <>
    <h4 className='products'>
    <div style={{ padding: "2rem" }}>
    Touch POS, Printer, Label, Scanner, Barcode, Racks, Weight Machine, Computer ...
    </div>
    </h4>
  <Cards />
      <Footer />
  </>;
}
