import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image1 from '../images/image-1.png';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out GST Billing Software for various fields!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='Computerized Accounting Barcode Billing Software'
              label='GST Software for Marts & Shop '
              path='/services'
            />
            <CardItem
              src='images/image-3bill.gif'
              text='Restaurant / Lodges / Sweet Shop Customize Software'
              label='POS Billing Software'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/image-ribbons.jpg'
              text='3/2 Inch Rolls, Barcode Label, Ribbon of every size available'
              label='Mystery'
              path='/services'
            />
            <CardItem
              src='images/image-3computer-setup.png'
              text='Window 10/11 PC with SSD / RAM & latest Intel generation'
              label='Computer Setup'
              path='/products'
            />
            <CardItem
              src='images/image-4scanner.png'
              text='All type of Hardware, Weight Machine, Poll Device available'
              label='Printer / Scanner / Cash Drawer'
              path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
